<template>
  <div class="subscription-wrapper">
    <div class="col-12">
      <div class="form-item">
        <div class="form-item__label info-popup">
          {{ $t("platform.settings.invoke_request_from_code") }}
        </div>
      </div>
    </div>
    <div class="col-12 align-self-end pb-2">
      <div class="form-item">
        <div class="form-item__field">
          <label class="checkbox">
            <input type="checkbox" v-model="data" />
            <span class="checkbox__visible"></span>
            <span class="checkbox__text">{{
              $t("platform.settings.auto_subscription")
            }}</span>
          </label>
          <div v-if="data" class="message mt-4">
            <button
              @click="$modal.show('showCode')"
              class="btn _border _mob100"
            >
              {{ $t("platform.settings.show_code") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal
      @closed="$modal.hide('showCode')"
      :width="780"
      name="showCode"
      height="auto"
      :scrollable="true"
      classes="creative-modal"
      :shiftY="0"
    >
      <ShowCode @closeModal="$modal.hide('showCode')" />
    </modal>
  </div>
</template>
<script>
import ShowCode from "./ShowCode.vue";

export default {
  name: "SubscriptionRequest",
  props: ["subscription"],
  components: { ShowCode },
  computed: {
    data: {
      get() {
        return this.subscription;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.message-icon {
  position: absolute;
  left: 0;
  fill: #9b9ba4;
}

.subscription-wrapper {
  border-radius: 10px;
  border: 1px solid #eeeef3;
  background: rgba(247, 247, 249, 0.2);
  padding: 32px 37px 0;
  margin-bottom: 35px;
}
</style>